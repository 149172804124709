import { TrancheGroup } from './trancheGroups';
import { IDealProfileFormField } from '@/condor/domain/vo/Finsight/Deal/DealProfileForm';
import { ProductAbbreviations } from '@/finsight/domain/Product';

import dealProfileStyles from '../dealProfile.scss';
import trancheStyles from '../Tranches/Tranche/tranche.scss';

const TRANCHE_FIELDS: { [key: string]: IDealProfileFormField } = {
  ID: {
    name: 'id',
    isHidden: true, // isHidden means that this field won't be rendered in tranche properties column,
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  IS_EXCLUDED: {
    name: 'isExlcuded', // This field is inserted into tranche object on frontend side.
    isBoolean: true,
    isHidden: true,
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  ORDERING_POSITION: {
    name: 'orderingPosition',
    label: 'Ordering Position', // Ordering Position is displayed in changes list
    isHidden: true,
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  ISSUER: {
    name: 'issuerId',
    label: 'Issuer',
    issuerSelect: true,
    isRequired: true,
    className: dealProfileStyles.requiredField,
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  CLASS: {
    name: 'class',
    label: 'Class',
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  CURRENCY: {
    name: 'currencyId',
    label: 'Currency',
    isSelect: true,
    optionsKey: 'currencies',
    isRequired: true,
    className: dealProfileStyles.requiredField,
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  ANNOUNCED_SIZE: {
    name: 'announcedSize',
    label: 'Announced Size (M)',
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  SIZE: {
    name: 'size',
    label: 'Current Size (M)',
    isNumber: true,
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  NO_GROW: {
    name: 'noGrow',
    label: 'No Grow?',
    isBoolean: true,
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  RE_OPEN: {
    name: 'reOpen',
    label: 'Re-Open / Tap',
    isBoolean: true,
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  SIZE_AFTER_TAP: {
    name: 'sizeAfterTap',
    label: 'Size After Tap (M)',
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  WAL_YRS: {
    name: 'weightedAverageLife',
    label: 'WAL (Yrs)',
    labelTooltipContent: 'Weighted Average Life (years)',
    isNumber: true,
    groupId: TrancheGroup.ANNOUNCEMENT,
    hiddenWithProducts: [ProductAbbreviations.HYC, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  WAL_EXT: {
    name: 'weightedAverageLife2',
    label: 'WAL ext',
    labelTooltipContent: 'Weighted Average Life (extended)',
    isNumber: true,
    groupId: TrancheGroup.ANNOUNCEMENT,
    hiddenWithProducts: [ProductAbbreviations.HYC, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  REGISTRATION: {
    name: 'registrationId',
    label: 'Registration',
    isSelect: true,
    optionsKey: 'registrations',
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  REG_RIGHTS: {
    name: 'regRights',
    label: 'Reg Rights',
    isRadio: true,
    config: {
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
        { value: '', label: 'None' },
      ],
    },
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  COUPON_TYPE: {
    name: 'couponTypeId',
    labelTooltipContent: 'Coupon Type',
    label: 'Coupon Type',
    isSelect: true,
    optionsKey: 'couponTypes',
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  RANKING: {
    name: 'rankingId',
    label: 'Security Type',
    isSelect: true,
    optionsKey: 'rankings',
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  SECURITY: {
    name: 'security',
    label: 'Security',
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  HYBRID: {
    name: 'hybrid',
    label: 'Hybrid',
    isBoolean: true,
    groupId: TrancheGroup.ANNOUNCEMENT,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  NON_COUNTABLE: {
    name: 'nonCountable',
    label: 'Non-countable',
    isBoolean: true,
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  LISTING: {
    name: 'listingId',
    label: 'Listing',
    isSelect: true,
    optionsKey: 'listingList',
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  UOP: {
    name: 'uopId',
    label: 'UOP',
    labelTooltipContent: 'Use of Proceeds',
    isSelect: true,
    optionsKey: 'uopList',
    groupId: TrancheGroup.ANNOUNCEMENT,
  },
  PAR_AMOUNT: {
    name: 'parAmount',
    label: 'Par Amount',
    isNumber: true,
    groupId: TrancheGroup.TIMING,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  TENOR_YRS: {
    name: 'tenorYrs',
    label: 'Tenor',
    isNumber: true,
    groupId: TrancheGroup.TIMING,
  },
  PERPETUAL: {
    name: 'perpetual',
    label: 'Perpetual?',
    isBoolean: true,
    groupId: TrancheGroup.TIMING,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  CALL: {
    name: 'call',
    label: 'Call',
    groupId: TrancheGroup.TIMING,
  },
  PAR_CALL_MONTHS: {
    name: 'parCallMonths',
    label: 'Par Call Months',
    groupId: TrancheGroup.TIMING,
  },
  EXP_MATURITY_DATE: {
    name: 'expectedMaturityDate',
    label: 'Exp Final',
    labelTooltipContent: 'Expected Final',
    isDate: true,
    groupId: TrancheGroup.TIMING,
    hiddenWithProducts: [ProductAbbreviations.HYC, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  MATURITY_DATE: {
    name: 'maturityDate',
    label: 'Maturity',
    isDate: true,
    groupId: TrancheGroup.TIMING,
  },
  CALL_DATE: {
    name: 'redemption',
    label: 'Call Date',
    isDate: true,
    groupId: TrancheGroup.TIMING,
  },
  FIRST_PAY_DATE: {
    name: 'firstPayDate',
    label: 'First Pay Date',
    isDate: true,
    groupId: TrancheGroup.TIMING,
  },
  RATINGS: {
    name: 'ratings',
    groupId: TrancheGroup.RATING_AGENCIES,
  },
  STRUCTURING_LEADS: {
    name: 'structuringLeads',
    label: 'Structuring Leads',
    companySelect: true,
    placeholder: 'Structuring Leads',
    groupId: TrancheGroup.LEADS,
    hiddenWithProducts: [ProductAbbreviations.HYC, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  LEFT_LEADS: {
    name: 'leftLeads',
    label: 'Left Leads',
    companySelect: true,
    placeholder: 'Left Leads',
    groupId: TrancheGroup.LEADS,
    hiddenWithProducts: [ProductAbbreviations.ABS, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  B_D_LEADS: {
    name: 'billingDelivering',
    label: 'Billing & Delivering',
    companySelect: true,
    placeholder: 'B&D',
    groupId: TrancheGroup.LEADS,
    hiddenWithProducts: [ProductAbbreviations.ABS, ProductAbbreviations.HYC, ProductAbbreviations.LM],
  },
  DEALER_MANAGERS: {
    name: 'dealerManagers',
    label: 'Dealer Managers',
    companySelect: true,
    placeholder: 'Dealer Managers',
    groupId: TrancheGroup.LEADS,
    hiddenWithProducts: [ProductAbbreviations.ABS, ProductAbbreviations.HYC, ProductAbbreviations.IGC],
  },
  JOINT_LEADS: {
    name: 'jointLeads',
    label: 'Joint Leads',
    companySelect: true,
    placeholder: 'Joint Leads',
    groupId: TrancheGroup.LEADS,
  },
  ACTIVE_LEADS: {
    name: 'activeLeads',
    label: 'Active Leads',
    companySelect: true,
    placeholder: 'Active Leads',
    groupId: TrancheGroup.LEADS,
  },
  PASSIVE_LEADS: {
    name: 'passiveLeads',
    label: 'Passive Leads',
    companySelect: true,
    placeholder: 'Passive Leads',
    groupId: TrancheGroup.LEADS,
  },
  CO_MANAGERS: {
    name: 'coManagers',
    label: 'Co-Managers',
    companySelect: true,
    placeholder: 'Co-Managers',
    groupId: TrancheGroup.LEADS,
  },
  COORDINATING_MANAGER: {
    name: 'coordinatingManagers',
    label: 'Coordinating Managers',
    companySelect: true,
    placeholder: 'Coord. manag.',
    groupId: TrancheGroup.LEADS,
  },
  DI_COORDINATOR: {
    name: 'diCoordinators',
    label: 'D&I Coordinators',
    companySelect: true,
    placeholder: 'D&I coordin.',
    groupId: TrancheGroup.LEADS,
  },
  DI: {
    name: 'di',
    label: 'D&I',
    isBoolean: true,
    groupId: TrancheGroup.LEADS,
  },
  ESG: {
    name: 'esgId',
    label: 'ESG',
    isSelect: true,
    optionsKey: 'esgList',
    groupId: TrancheGroup.DEAL_MODIFIERS,
  },
  BAIL_IN: {
    name: 'bailIn',
    label: 'Bail In',
    isRadio: true,
    config: {
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
        { value: '', label: 'None' },
      ],
    },
    groupId: TrancheGroup.DEAL_MODIFIERS,
  },
  COC: {
    name: 'coc',
    label: 'COC',
    isBoolean: true,
    groupId: TrancheGroup.DEAL_MODIFIERS,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  SMR: {
    name: 'smr',
    label: 'SMR',
    isBoolean: true,
    groupId: TrancheGroup.DEAL_MODIFIERS,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  GUIDANCE_IPT: {
    name: 'guidanceIpt',
    label: 'Init. Price Talk',
    groupId: TrancheGroup.PRICE_PROGRESSION,
  },
  GUIDANCE: {
    name: 'guidance',
    label: 'Guidance',
    groupId: TrancheGroup.PRICE_PROGRESSION,
  },
  LAUNCH: {
    name: 'launch',
    label: 'Launch',
    groupId: TrancheGroup.PRICE_PROGRESSION,
  },
  PRICING: {
    name: 'pricing',
    label: 'Pricing',
    groupId: TrancheGroup.PRICE_PROGRESSION,
  },
  DROPPED_TRANCHE: {
    name: 'droppedTranche',
    label: 'Dropped Tranche',
    isBoolean: true,
    groupId: TrancheGroup.PRICE_PROGRESSION,
  },
  UPSIZED: {
    name: 'upsized',
    label: 'Upsized',
    isBoolean: true,
    groupId: TrancheGroup.PRICE_PROGRESSION,
  },
  CREDIT_ENHANCEMENT: {
    name: 'creditEnhancement',
    label: 'C/E',
    labelTooltipContent: 'Credit Enhancement',
    isNumber: true,
    groupId: TrancheGroup.PRICING_DETAILS,
    hiddenWithProducts: [ProductAbbreviations.HYC, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  LOAN_TO_VALUE: {
    name: 'loanToValue',
    label: 'LTV',
    labelTooltipContent: 'Loan-to-Value',
    isNumber: true,
    groupId: TrancheGroup.PRICING_DETAILS,
    hiddenWithProducts: [ProductAbbreviations.HYC, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  COUPON: {
    name: 'coupon',
    label: 'Coupon',
    groupId: TrancheGroup.PRICING_DETAILS,
  },
  ISSUE_PRICE: {
    name: 'issuePrice',
    label: 'Price',
    isNumber: true,
    groupId: TrancheGroup.PRICING_DETAILS,
  },
  YIELD: {
    name: 'yield',
    label: 'Yield',
    groupId: TrancheGroup.PRICING_DETAILS,
  },
  SPREAD: {
    name: 'spread',
    label: 'Spread (bps)',
    groupId: TrancheGroup.PRICING_DETAILS,
  },
  BENCHMARK: {
    name: 'benchmarkId',
    label: 'Benchmark',
    isSelect: true,
    optionsKey: 'benchmarks',
    groupId: TrancheGroup.PRICING_DETAILS,
  },
  MWC_T: {
    name: 'mwcTPlus',
    label: 'MWC T+',
    groupId: TrancheGroup.PRICING_DETAILS,
    labelTooltipContent: 'Make Whole Call',
  },
  ERISA_ELIGABLE: {
    name: 'erisaEligible',
    label: 'ERISA',
    isBoolean: true,
    groupId: TrancheGroup.PRICING_DETAILS,
    hiddenWithProducts: [ProductAbbreviations.HYC, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  BACK_END_BENCHMARK: {
    name: 'backendBenchmark',
    label: 'Back-End Benchmark',
    groupId: TrancheGroup.PRICING_DETAILS,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  BACK_END_SPREAD: {
    name: 'backendSpread',
    label: 'Back-End Spread',
    isNumber: true,
    groupId: TrancheGroup.PRICING_DETAILS,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  RETAINED: {
    name: 'retained',
    label: 'Retained',
    isBoolean: true,
    groupId: TrancheGroup.PRICING_DETAILS,
    hiddenWithProducts: [ProductAbbreviations.HYC, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  // interestsOnly is visible when the selected ABS Sector is CMBS
  INTEREST_ONLY: {
    name: 'interestOnly',
    label: 'I/O',
    labelTooltipContent: 'Interest Only',
    isBoolean: true,
    groupId: TrancheGroup.PRICING_DETAILS,
    hiddenWithProducts: [ProductAbbreviations.HYC, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  IPT_PXD: {
    name: 'iptPxd',
    label: 'IPT PXD',
    groupId: TrancheGroup.PERFORMANCE,
    labelTooltipContent: 'IPT Pricing Progression',
  },
  BOOK_SIZE: {
    name: 'bookSize',
    label: 'Book Size (M)',
    isNumber: true,
    groupId: TrancheGroup.PERFORMANCE,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  PEAK_BOOK_M: {
    name: 'peakBook',
    label: 'Peak Book (M)',
    isNumber: true,
    groupId: TrancheGroup.PERFORMANCE,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  NEW_ISSUE_CONCESSION: {
    name: 'newIssueConcession',
    label: 'NIC (bps)',
    labelTooltipContent: 'New Issue Concessions',
    groupId: TrancheGroup.PERFORMANCE,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  BOOK_X_COVERED: {
    name: 'bookXCovered',
    label: 'Book X-Covered',
    autoCalculated: true,
    groupId: TrancheGroup.PERFORMANCE,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  BOOK_ATTRITION: {
    name: 'bookAttrition',
    label: 'Book Attrition',
    autoCalculated: true,
    groupId: TrancheGroup.PERFORMANCE,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  DEBUT: {
    name: 'debutId',
    label: 'Debut',
    isSelect: true,
    optionsKey: 'debutList',
    groupId: TrancheGroup.PERFORMANCE,
    hiddenWithProducts: [ProductAbbreviations.ABS],
  },
  TRADING_LEVEL: {
    name: 'tradingLevel',
    label: 'Trading Level',
    groupId: TrancheGroup.PERFORMANCE,
    hiddenWithProducts: [ProductAbbreviations.ABS, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  TRADING_DELTA: {
    name: 'tradingDelta',
    label: 'Trading Delta',
    isNumber: true,
    groupId: TrancheGroup.PERFORMANCE,
    hiddenWithProducts: [ProductAbbreviations.ABS, ProductAbbreviations.IGC, ProductAbbreviations.LM],
  },
  CUSIPS: {
    name: 'cusips',
    label: 'CUSIP',
    groupId: TrancheGroup.UNIQUE_IDENTIFIERS,
  },
  CUSIPS_REGS: {
    name: 'cusipsRegs',
    label: 'RegS CUSIP',
    groupId: TrancheGroup.UNIQUE_IDENTIFIERS,
  },
  FIGI: {
    name: 'figi',
    label: 'FIGI',
    groupId: TrancheGroup.UNIQUE_IDENTIFIERS,
  },
  ISINS: {
    name: 'isins',
    label: 'ISIN',
    groupId: TrancheGroup.UNIQUE_IDENTIFIERS,
  },
  COMMENTS: {
    name: 'comments',
    label: 'Comments',
    isTextarea: true,
    className: trancheStyles.commentsCell,
    groupId: TrancheGroup.COMMENTS,
  },
};

export default TRANCHE_FIELDS;
