export const UPLOAD_PRESENTATION_PREVIEW_WIDTH = 138;

export const BYTES_IN_TWO_MEGABYTES = 2097152;
export const BYTES_IN_TWO_GIGABYTES = 2147483648;

export const UPLOAD_VIEW_ONLY_ALLOWED_FILES = '.pdf';

export const UPLOAD_DOCUMENTS_ALLOWED_FILES = '';
export const UPLOAD_DOCUMENTS_ALLOWED_FILES_TEXT = 'CDI, DOC, PDF, PPT, TXT, ZIP, etc';

export const UPLOAD_ATTACHMENTS_ALLOWED_FILES = '.pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx';
export const UPLOAD_ATTACHMENTS_ALLOWED_FILES_TEXT = 'PDF, PPT, DOC, XLS';

export const UPLOAD_COMMERCIAL_VIDEO_ALLOWED_FILES = '.avi, .flv, .mkv, .mov, .mp4, .wmv';
export const UPLOAD_COMMERCIAL_VIDEO_ALLOWED_FILES_TEXT = 'AVI, FLV, MKV, MOV, MP4, WMV';

export const UPLOAD_LOGO_ALLOWED_FILES = '.jpg, .png';
export const UPLOAD_LOGO_ALLOWED_FILES_TEXT = 'JPG, PNG';
export const UPLOAD_BACKGROUND_ALLOWED_FILES_TEXT = 'JPG, PNG (must be at least 1600x1024 px)';
export const UPLOAD_LOGO_SIZE_SUPPORTED_DESCRIPTION = 'The max file size supported is 2 megabytes.';

export const UPLOAD_PRESENTATION_ALLOWED_FILES = '.pdf';
export const UPLOAD_PRESENTATION_ALLOWED_FILES_TEXT = 'PDF';

export const UPLOAD_PRESENTATION_ADMIN_SLIDE_ALLOWED_FILES_PNG = '.png';
export const UPLOAD_PRESENTATION_ADMIN_SLIDE_ALLOWED_FILES_TEXT_PNG = 'PNG';

export const UPLOAD_PRESENTATION_ADMIN_SLIDE_ALLOWED_FILES_JPEG = '.jpg, .jpeg';
export const UPLOAD_PRESENTATION_ADMIN_SLIDE_ALLOWED_FILES_TEXT_JPEG = 'JPG, JPEG';

export const UPLOAD_PRESENTATION_ADMIN_SLIDE_ALLOWED_FILES_SVG = '.svg';
export const UPLOAD_PRESENTATION_ADMIN_SLIDE_ALLOWED_FILES_TEXT_SVG = 'SVG';

export const UPLOAD_PRESENTATION_MEDIA_AUDIO_ALLOWED_FILES = '.mp3';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_ALLOWED_FILES_TEXT = 'MP3';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_TEXT_NAME = 'Audio';

export const UPLOAD_PRESENTATION_MEDIA_VIDEO_ALLOWED_FILES = '.mp4';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_ALLOWED_FILES_TEXT = 'MP4';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_TEXT_NAME = 'Video';

export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_ALLOWED_FILES = '.png, .jpg, .jpeg';
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_ALLOWED_FILES_TEXT = 'PNG, JPG JPEG';

export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_FILES = '.csv, .txt';
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_FILES_TEXT = 'CSV, TXT';

export const UPLOAD_SSO_CERTIFICATE_ALLOWED_FILES = '.tex';
export const UPLOAD_SSO_CERTIFICATE_ALLOWED_FILES_TEXT = 'TEX';

export const UPLOAD_TABLE_COLUMNS = ['file', 'name', 'actions', 'dragAndDrop'];
export const UPLOAD_TABLE_WIDTHS = { file: '43%', name: '42%', actions: '10%', dragAndDrop: '5%' };

export const UPLOAD_TABLE_COLUMNS_WITHOUT_NAME = ['file', 'actions', 'dragAndDrop'];
export const UPLOAD_TABLE_WIDTHS_WITHOUT_NAME = { file: '90%', actions: '5%', dragAndDrop: '5%' };

export const UPLOAD_TABLE_COLUMNS_WITHOUT_DND = ['file', 'name', 'actions'];
export const UPLOAD_TABLE_WIDTHS_WITHOUT_DND = { file: '45%', name: '45%', actions: '10%' };
