import { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';
import Children from './Children';
import Clearfix from '@dealroadshow/uikit/core/components/Clearfix';

import formsStyles from './Forms/forms.scss';
import IconPlus from '@dealroadshow/uikit/core/components/Icon/IconPlus';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';

interface IProps {
  fields: { [key: string]: any },
  logicOperators: { [key: string]: any }[],
  onFormChange: () => void,
  onFormArray: { [key: string]: any },
  conditionalOperators: { [key: string]: any }[],
}

class Forms extends Component<IProps> {
  constructor(props) {
    super(props);
    this.handleAddField = this.handleAddField.bind(this);
    this.handleRemoveGroup = this.handleRemoveGroup.bind(this);
  }

  handleAddField(groupName) {
    this.props.onFormArray.push(`${ groupName }.children`, {
      lo: this.props.logicOperators[1].value,
      co: this.props.conditionalOperators[0].value,
      groupSuffix: '',
      value: '',
    });
  }

  handleRemoveGroup(groupIndex) {
    this.props.fields.remove(groupIndex);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return this.props.fields.map((groupName, groupIndex) => {
      let group = this.props.fields.get(groupIndex);
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={ groupIndex }>
          { groupIndex > 0 && (
            <div className={ formsStyles.formGroupLoWrapper }>
              <Field
                name={ `${ groupName }.lo` }
                component={ (fieldProps) => (
                  <Select
                    selectClassName="drop-up"
                    label={ fieldProps.label }
                    name={ fieldProps.input.name }
                    value={ fieldProps.input.value }
                    onChange={ fieldProps.input.onChange }
                    onBlur={ () => fieldProps.input.onBlur(fieldProps.input.value) }
                    options={ this.props.logicOperators }
                    simpleValue
                    dataTest="condorTechnicalSupportQueryFilterGroupSelect"
                  />
                ) }
              />
            </div>
          ) }
          <div className={ formsStyles.fieldsForm }>
            <div className={ formsStyles.formGroupFieldActions }>
              <IconPlus onClick={ () => this.handleAddField(groupName) } />
              <IconTrash
                className={ formsStyles.formGroupTrashIcon }
                onClick={ () => this.handleRemoveGroup(groupIndex) }
              />
            </div>
            <div className={ formsStyles.formGroupFieldLabel }>
              Group: { group.label }
            </div>
            <FieldArray
              name={ `${ groupName }.children` }
              label={ group.label }
              component={ Children }
              logicOperators={ this.props.logicOperators }
              conditionalOperators={ this.props.conditionalOperators }
              onFormChange={ this.props.onFormChange }
              onFormArray={ this.props.onFormArray }
            />
            <Clearfix />
          </div>
        </div>
      );
    });
  }
}

export default Forms;
