import * as actionTypes from '../actionTypes';

const initialState = [
  {
    name: 'Deal Settings',
    link: 'deal-settings',
    disabled: false,
    dataTest: 'dealSettingsLink',
  },
  {
    name: 'Deal Files',
    link: 'deal-files',
    disabled: false,
    dataTest: 'dealFilesLink',
  },
  {
    name: 'Deal Slides',
    link: 'deal-slides',
    disabled: false,
    dataTest: 'dealSlidesLink',
  },
  {
    name: 'Audio/Video',
    link: 'audio-video',
    disabled: false,
    dataTest: 'audioVideoLink',
  },
  {
    name: 'Analytics',
    link: 'analytics',
    disabled: false,
    dataTest: 'analyticsLink',
  },
  {
    name: 'Audit Trail',
    link: 'audit-trail',
    disabled: false,
    disabledTooltip: '',
    dataTest: 'audioTrailLink',
  },
  {
    name: 'Custom Modal',
    link: 'custom-modal',
    disabled: false,
    dataTest: 'customModalLink',
  },
  {
    name: 'Login Limits',
    link: 'login-limits',
    disabled: true,
    dataTest: 'loginLimitsLink',
  },
];

export default function tabs(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ROADSHOW_TABS_ENABLED:
      return state.map((tab) => {
        if (tab.link === action.payload) {
          return {
            ...tab,
            disabled: false,
          };
        }
        return tab;
      });
    case actionTypes.ROADSHOW_TABS_DISABLED:
      return state.map((tab) => {
        if (tab.link === action.payload.link) {
          return {
            ...tab,
            disabled: true,
            disabledTooltip: action.payload.disabledTooltip,
          };
        }
        return tab;
      });

    default:
      return state;
  }
}
