export const createCompanyOptionWithIssuerTicker = (
  {
    id,
    name,
    issuerTicker,
  }: {
    id: string,
    name: string,
    issuerTicker: string,
  },
) => (
  {
    value: id,
    label: `${ name }${ issuerTicker ? ` (${ issuerTicker })` : '' }`,
    issuerTicker,
  }
);

export const createCompanyOptionWithIssuerTickerAndAbbreviation = (
  {
    id,
    name,
    issuerTicker,
    abbreviation,
  }: {
    id?: string,
    name: string,
    issuerTicker?: string | null,
    abbreviation?: string | null,
  },
) => (
  {
    value: id,
    label: `${ name }${ abbreviation ? ` ${ abbreviation }` : '' }${ issuerTicker ? ` (${ issuerTicker })` : '' }`,
    abbreviation,
    issuerTicker,
    name,
  }
);
