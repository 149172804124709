import { DEAL_PROFILE_FIELDS } from './dealProfileFields';
import TRANCHE_FIELDS from '@/condor/ui/components/Finsight/DealProfile/constants/trancheFields';
import { TrancheGroup } from '@/condor/ui/components/Finsight/DealProfile/constants/trancheGroups';

export const DEAL_PROFILE_FIELDS_ARRAY = Object.values(DEAL_PROFILE_FIELDS);
export const DEAL_PROFILE_TRANCHE_FIELDS_ARRAY = Object.values(TRANCHE_FIELDS);
export const TRANCHES_VISIBLE = 7;

export const TRANCHE_UNIQUE_FIELD_NAMES = DEAL_PROFILE_TRANCHE_FIELDS_ARRAY.reduce(
    (acc, field) => ((
      field.groupId === TrancheGroup.PRICE_PROGRESSION
      || field.groupId === TrancheGroup.UNIQUE_IDENTIFIERS
      ) ? [...acc, field.name] : acc),
    [],
);
