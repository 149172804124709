import IconFileCdi from '@dealroadshow/uikit/core/components/Icon/IconFileCdi';
import IconFileDoc from '@dealroadshow/uikit/core/components/Icon/IconFileDoc';
import IconFilePdf from '@dealroadshow/uikit/core/components/Icon/IconFilePdf';
import IconFilePpt from '@dealroadshow/uikit/core/components/Icon/IconFilePpt';
import IconFileTxt from '@dealroadshow/uikit/core/components/Icon/IconFileTxt';
import IconFileXls from '@dealroadshow/uikit/core/components/Icon/IconFileXls';
import IconVideo from '@dealroadshow/uikit/core/components/Icon/IconVideo';
import IconFileZip from '@dealroadshow/uikit/core/components/Icon/IconFileZip';
import IconFileJpg from '@dealroadshow/uikit/core/components/Icon/IconFileJpg';
import IconFilePng from '@dealroadshow/uikit/core/components/Icon/IconFilePng';
import IconDocument from '@dealroadshow/uikit/core/components/Icon/IconDocument';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';

export default function mapFileExtensionToIconType(extension: string): IconComponentType {
  switch (extension) {
    case 'cdi':
      return IconFileCdi;
    case 'doc':
    case 'docx':
      return IconFileDoc;
    case 'ppt':
    case 'pptx':
      return IconFilePpt;
    case 'xls':
    case 'xlsx':
      return IconFileXls;
    case 'txt':
      return IconFileTxt;
    case 'zip':
      return IconFileZip;
    case 'pdf':
      return IconFilePdf;
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'mkv':
    case 'flv':
      return IconVideo;
    case 'jpeg':
    case 'jpg':
      return IconFileJpg;
    case 'png':
      return IconFilePng;
    default:
      return IconDocument;
  }
}
