import actionTypes from './actionTypes';
import { getItemReducer } from '@/Framework/State/Redux/itemFactory';
import { getDealProfileFieldsOptions, getCompanyFieldOptionMapperByFieldName } from './helpers';
import { OTHER_OR_UNSPECIFIED_RATING_AGENCY_ID } from './constants';

const newState = {
  isInitialized: false,
  ratingAgencies: [],
  options: {},
  // In this object we will store the filtered options for 5 dropdowns with companies in Deal Details section.
  filteredCompaniesOptionsByFields: {},
  // In these objects we will store data to pre-populate in Create Deal Profile form when it is opened from Company.
  initialIssuer: {},
  initialDeal: {},
};

export default getItemReducer(
  actionTypes,
  newState,
  {
    [actionTypes.INITIALIZE_CONDOR_DEAL_CARD]: (state) => (
      {
        ...state,
        isInitialized: true,
      }
    ),
    [actionTypes.GET_CONDOR_DEAL_CARD_INITIAL_DATA_SUCCESS]: (state, action) => {
      const [initialIssuer, deals] = action.payload;
      return {
        ...state,
        initialIssuer,
        initialDeal: deals.collection.length ? deals.collection[0] : {},
      };
    },
    [actionTypes.GET_CONDOR_DEAL_CARD_OPTIONS_DATA_SUCCESS]: (state, action) => {
      const [
        ratingAgencies,
        countries,
        products,
        regions,
        sectors,
        currencies,
        rankings,
        couponTypes,
        registrations,
        benchmarks,
        dictionary,
      ] = action.payload;

      const ratingAgenciesWithoutOtherUnspecified = ratingAgencies.filter(
        (agency) => agency.id !== OTHER_OR_UNSPECIFIED_RATING_AGENCY_ID,
      );

      return {
        ...state,
        ratingAgencies: ratingAgenciesWithoutOtherUnspecified,
        options: getDealProfileFieldsOptions(
          {
            countries,
            products,
            regions,
            sectors,
            currencies,
            rankings,
            couponTypes,
            registrations,
            benchmarks: benchmarks.collection,
            statusList: dictionary.statusList,
            debutList: dictionary.debutList,
            esgList: dictionary.esgList,
            listingList: dictionary.listingList,
            lmTypeList: dictionary.lmTypeList,
            uopList: dictionary.uopList,
          },
        ),
      };
    },
    [actionTypes.FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS_SUCCESS]: (state, action) => (
      {
        ...state,
        filteredCompaniesOptionsByFields: {
          ...state.filteredCompaniesOptionsByFields,
          [action.payload.fieldName]: action.payload.collection
            .map(getCompanyFieldOptionMapperByFieldName(action.payload.fieldName)),
        },
      }
    ),
    [actionTypes.CLEAR_DEAL_CARD_COMPANY_FIELD_OPTIONS]: (state, action) => (
      {
        ...state,
        filteredCompaniesOptionsByFields: {
          ...state.filteredCompaniesOptionsByFields,
          [action.payload]: [],
        },
      }
    ),
    [actionTypes.CREATE_DEAL_WITH_MAP_TO_FILING]: (state) => (
      {
        ...state,
        isCreating: true,
      }
    ),
    [actionTypes.CREATE_DEAL_WITH_MAP_TO_FILING_SUCCESS]: (state) => (
      {
        ...state,
        isCreating: false,
      }
    ),
    [actionTypes.CREATE_DEAL_WITH_MAP_TO_FILING_ERROR]: (state) => (
      {
        ...state,
        isCreating: false,
      }
    ),
    [actionTypes.UPDATE_DEAL_WITH_MAP_TO_FILING]: (state) => (
      {
        ...state,
        isUpdating: true,
      }
    ),
    [actionTypes.UPDATE_DEAL_WITH_MAP_TO_FILING_SUCCESS]: (state) => (
      {
        ...state,
        isUpdating: false,
      }
    ),
    [actionTypes.UPDATE_DEAL_WITH_MAP_TO_FILING_ERROR]: (state) => (
      {
        ...state,
        isUpdating: false,
      }
    ),
  },
);
