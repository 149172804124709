import React from 'react';
import * as uploadTypes from '../../uploadTypes';
import * as processingTypes from '../../processingTypes';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import ProgressBar from '@dealroadshow/uikit/core/components/Loader/ProgressBar';
import IconCancel from '@dealroadshow/uikit/core/components/Icon/IconCancel';
import {
  convertUploadTypesToString,
  convertProcessingTypesToString,
  isShowProgressByUploadType,
} from '../../helpers/convertUploadTypes';
import UploadDropzoneOneFile, { IUploadDropzoneOneFileProps } from '../UploadDropzoneOneFile';

import uploadStyles from '../../upload.scss';
import styles from './uploadOneFile.scss';

interface IUploadOneFileProps extends IUploadDropzoneOneFileProps {
  uploadFile: Record<string, any>,
  onUploadCancel: (uuid: string) => void,
}

const UploadOneFile = ({ uploadFile, onUploadCancel, ...dropZoneProps }: IUploadOneFileProps) => {
  const handleRemove = () => onUploadCancel(uploadFile.process.uuid);

    let file = uploadFile;
    return (
      <div className={ uploadStyles.formFileUpload }>
        <div className={ styles.formFileUploadOneFile }>
          { file.process.uuid && (
            <IconCancel
              className={ styles.trashIcon }
              onClick={ handleRemove }
            />
          ) }
          { file.process.uuid && (
            <Spinner
              isVisible={
                file.backgroundProcessingStatus === processingTypes.PROCESSING
                || file.process.status !== uploadTypes.UPLOAD_DONE
              }
              overlay
            />
          ) }
          <span className={ styles.formFileUploadOneFileStatus }>
            { file.backgroundProcessingStatus
              ? convertProcessingTypesToString(file.backgroundProcessingStatus)
              : convertUploadTypesToString(file.process.status) }
          </span>
          { isShowProgressByUploadType(file.process.status) && (
            <ProgressBar
              value={ file.process.progress }
              className={ styles.progressBar }
              filledClassName={ styles.progressBarFilled }
            />
          ) }
          { isShowProgressByUploadType(file.process.status) && (
            <span className={ styles.formFileUploadOneFilePercent }>{ file.process.progress }% </span>
          ) }
          <UploadDropzoneOneFile fileName={ file.name } { ...dropZoneProps } />
        </div>
      </div>
    );
};

export default UploadOneFile;
