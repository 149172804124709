import { useState, useEffect, ElementType, ReactNode } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Input from '@dealroadshow/uikit/core/components/Input';
import Textarea from '@dealroadshow/uikit/core/components/Textarea';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import ProgressBar from '@dealroadshow/uikit/core/components/Loader/ProgressBar';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import DataTable, { dataTableStyles, IColumn } from '@dealroadshow/uikit/core/components/Table/DataTable';
import {
  convertUploadTypesToString,
  isShowProgressByUploadType,
  isShowProgressForTableByUploadType,
  isShowSpinnerForTableByUploadType,
} from '../helpers/convertUploadTypes';
import UploadDropzoneMultipleFiles from './UploadDropzoneMultipleFiles';
import mapFileExtensionToIconType from '@/Framework/dataHelpers/mapFileExtensionToIconType';
import getFileExtension from '@/Framework/dataHelpers/string/getFileExtension';
import isEmptyString from '@/Framework/dataHelpers/string/isEmptyString';

import textStyles from '@dealroadshow/uikit/core/styles/helpers/text.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from '../upload.scss';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import IconDescription from '@dealroadshow/uikit/core/components/Icon/IconDescription';
import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import IconUnlock from '@dealroadshow/uikit/core/components/Icon/IconUnlock';

const defaultFileToFieldValueProcessor = (file: any, uuid: string) => ({
  uuid,
  name: file.name,
  disclaimer: '',
  viewOnly: false,
});

interface IProcess {
  status: string,
  progress: number,
  uuid: string,
}

interface IFile {
  url: string,
  name: string,
  originalName: string,
  process: IProcess,
  initialFile: any,
  extension: string,
  uuid?: string,
}

interface IUploadFile {
  sort: { uuid: string }[],
  files: { [uuid: string]: IFile },
}

export interface IDropzoneProps {
  onUpload: (files: File[]) => void,
  uploadFiles: IUploadFile,
}

interface IProps {
  uploadList: any[],
  uploadFiles: IUploadFile,
  fields: any,
  columns?: string[],
  widths?: { [name: string]: string },
  formFieldName: string,
  uploadName: string,
  allowedFileText: string,
  viewOnlyFileTypes?: string,
  allowedFileTypes?: string,
  maxSize?: number,
  maxSizeUnits?: string,
  limit?: number,
  supportsDisclaimer?: boolean,
  onUpload: (files: File[], callback: (id: string, index: number) => void) => void,
  onUploadCancel: (id: string) => void,
  onUploadOrder: (oldIndex: number, newIndex: number) => void,
  setCustomDisclaimer?: (rowIndex: number, isDisclaimer: boolean) => void,
  setDisclaimer?: (rowIndex: number, disclaimer: string) => void,
  setViewOnly?: (rowIndex: number, viewOnly: boolean) => void,
  fieldComponent: ElementType,
  fileFieldTitle?: string,
  nameFieldTooltipContent?: string,
  fileToFieldValueProcessor?: (value: IFile, uuid: string) => any,
  UploadDropzoneArea?: (props: IDropzoneProps) => ReactNode,
}

interface IDisclaimerModal {
  isVisible: string,
  initialDisclaimer: string,
}

const UploadTable = ({
  uploadName,
  uploadFiles,
  uploadList,
  fields,
  formFieldName,
  columns = [],
  widths = {},
  viewOnlyFileTypes = '',
  allowedFileTypes = '',
  allowedFileText,
  maxSizeUnits,
  maxSize = Infinity,
  limit = Infinity,
  supportsDisclaimer = false,
  onUpload,
  onUploadCancel,
  onUploadOrder,
  setCustomDisclaimer,
  setDisclaimer,
  setViewOnly,
  fieldComponent: Field,
  fileFieldTitle = 'Files',
  nameFieldTooltipContent = 'Document name seen by investors',
  fileToFieldValueProcessor = defaultFileToFieldValueProcessor,
  UploadDropzoneArea,
}: IProps) => {
  const [files, setFiles] = useState<{ [uuid: string]: boolean }>({});
  const [selected, setSelected] = useState<{ [uuid: string]: boolean }>({});
  const [disclaimerModal, setDisclaimerModal] = useState<IDisclaimerModal>({
    isVisible: null,
    initialDisclaimer: '',
  });

  useEffect(() => {
    if (!isEqual(Object.keys(files), Object.keys(uploadFiles.files))) {
      const localFiles = {};
      const localSelected = { ...selected };

      Object.keys(uploadFiles.files)
        .forEach((uuid) => {
          localFiles[uuid] = true;

          if (!files[uuid] && !uploadFiles.files[uuid].initialFile) {
            fields.push(fileToFieldValueProcessor(uploadFiles.files[uuid], uuid));

            localSelected[uuid] = false;
          } else if (!files[uuid] && uploadFiles.files[uuid].initialFile) {
            localSelected[uuid] = false;
          }
        });

      setFiles(localFiles);
      setSelected(localSelected);
    }
  }, [uploadFiles.files]);

  const isViewOnlySupported = (uuid: string): boolean => {
    if (isEmptyString(viewOnlyFileTypes)) return false;

    const acceptedExt = viewOnlyFileTypes.split(',')
      .map((accept) => accept.trim());
    const { extension } = uploadFiles.files[uuid];

    return acceptedExt.includes(extension);
  };

  const getColumnWidth = (columnName: string): string => widths[columnName];

  const isColumnVisible = (columnName: string): boolean => columns.includes(columnName);

  const handleRemoveFile = (uuid: string, index: number): void => {
    fields.remove(index);
    onUploadCancel(uuid);
    setSelected((prevState) => {
      delete prevState[uuid];
      return prevState;
    });
  };

  const handleUploadOrderChange = (oldIndex: number, newIndex: number): void => {
    onUploadOrder(oldIndex, newIndex);
    fields.move(oldIndex, newIndex);
  };

  const handleViewOnlyChange = (rowIndex: number): void => {
    const { viewOnly } = uploadList[rowIndex];
    setViewOnly(rowIndex, !viewOnly);
  };

  const showModal = (uuid: string, initialDisclaimer: string): void => setDisclaimerModal({
    isVisible: uuid,
    initialDisclaimer,
  });

  const hideModal = (): void => setDisclaimerModal({
    isVisible: null,
    initialDisclaimer: '',
  });

  const handleCancelDisclaimer = (rowIndex: number): void => {
    if (!isEqual(uploadList[rowIndex].disclaimer, disclaimerModal.initialDisclaimer)) {
      setDisclaimer(rowIndex, disclaimerModal.initialDisclaimer);
    }

    hideModal();
  };

  const handleSaveDisclaimer = (rowIndex: number): void => {
    const hasDisclaimer = uploadList[rowIndex]
      && (('disclaimer' in uploadList[rowIndex]) && !isEmptyString(uploadList[rowIndex].disclaimer));

    setCustomDisclaimer(rowIndex, hasDisclaimer);
    hideModal();
  };

  const uploadDropzoneArea = UploadDropzoneArea
    ? (
      <UploadDropzoneArea
        onUpload={ (files) => onUpload(files, handleRemoveFile) }
        uploadFiles={ uploadFiles }
      />
    )
    : (
      <>
        <UploadDropzoneMultipleFiles
          onUpload={ (files) => onUpload(files, handleRemoveFile) }
          allowedFileTypes={ allowedFileTypes }
          allowedFileText={ allowedFileText }
          uploadName={ uploadName }
          maxSize={ maxSize }
          maxSizeUnits={ maxSizeUnits }
          limit={ limit }
        />
        { formFieldName === 'uploadCommercialVideoList' && (
          <div className={ cn(styles.tip, styles.videoTip) }>
            The max file size supported 2 gigabytes. Video may take a few
            minutes to convert and display in presentation
            viewer.
          </div>
        ) }
      </>
    );

  const fileCellCallback = ({
    row,
    rowIndex,
  }) => {
    const filename = uploadFiles.files[row.uuid].originalName;
    const extension = getFileExtension(filename);

    const IconComponent = mapFileExtensionToIconType(extension);

    return (
      <div>
        <div className={ styles.uploadListFileWrp }>
          <div className={ styles.uploadListFileIcon }>
            <IconComponent />
          </div>
          <div
            className={ styles.uploadListFileName }
            data-test="uploadListFileName"
          >
            { filename }
            { isShowProgressByUploadType(uploadFiles.files[row.uuid].process.status) && (
              <div className={ styles.uploadListPercentage }>
                <Tooltip
                  placement="bottom"
                  content={ convertUploadTypesToString(uploadFiles.files[row.uuid].process.status) }
                >
                  { uploadFiles.files[row.uuid].process.progress }%
                </Tooltip>
              </div>
            ) }
            { isShowSpinnerForTableByUploadType(uploadFiles.files[row.uuid].process.status) && (
              <div className={ styles.uploadListSpinnerWrp }>
                <Tooltip
                  placement="bottom"
                  content={ convertUploadTypesToString(uploadFiles.files[row.uuid].process.status) }
                >
                  <Spinner size="small" />
                </Tooltip>
              </div>
            ) }
            { isShowProgressForTableByUploadType(uploadFiles.files[row.uuid].process.status) && (
              <ProgressBar
                className={ styles.uploadListProgressBar }
                value={ uploadFiles.files[row.uuid].process.progress }
              />
            ) }
          </div>
        </div>
        { !isEmpty(uploadList)
          && uploadList[rowIndex]
          && disclaimerModal.isVisible === row.uuid
          && (
            <Modal
              isVisible
              title={ `File Disclaimer for ${ filename }` }
              showCloseButton={ false }
              closeOnEsc={ false }
              dataTest="fileDisclaimerModal"
              footer={ (
                <>
                  <Button
                    variant={ ButtonVariantType.action }
                    onClick={ () => handleSaveDisclaimer(rowIndex) }
                    title="Save"
                    dataTest="uploadModalSaveButton"
                  />
                  <Button
                    variant={ ButtonVariantType.text }
                    onClick={ () => handleCancelDisclaimer(rowIndex) }
                    title="Cancel"
                    dataTest="uploadModalCancelButton"
                  />
                </>
              ) }
            >
              <Field
                name={ `${ formFieldName }[${ rowIndex }].disclaimer` }
                component={ Textarea }
                label="File Disclaimer"
                textareaClassName={ styles.modalDisclaimerTextarea }
                isNarrow
                dataTest="uploadTableTextarea"
              />
            </Modal>
          ) }
      </div>
    );
  };

  const tableColumns: IColumn[] = [
    {
      name: 'file',
      width: getColumnWidth('file'),
      isVisible: isColumnVisible('file'),
      title: fileFieldTitle,
      cellCallback: fileCellCallback,
    },
    {
      name: 'name',
      width: getColumnWidth('name'),
      isVisible: isColumnVisible('name'),
      className: cn(dataTableStyles.inputCol, textStyles.verticalAlignTop),
      title: (() => (
        <div>
          Display Name
          <span className={ dataTableStyles.dataTableHeaderIcon }>
            <Tooltip
              placement="bottom"
              content={ nameFieldTooltipContent }
            >
              <IconQuestion />
            </Tooltip>
          </span>
        </div>
      ))(),
      cellCallback: ({ rowIndex }) => (
        <Field
          name={ `${ formFieldName }[${ rowIndex }].name` }
          component={ Input }
          dataTest={ `${ formFieldName }[${ rowIndex }].nameInput` }
          isNarrow
        />
      ),
    },
    {
      name: 'actions',
      title: '',
      width: getColumnWidth('actions'),
      isVisible: isColumnVisible('actions'),
      className: dataTableStyles.actionCol,
      cellCallback: ({
        row,
        rowIndex,
      }) => {
        const hasDisclaimer = uploadList[rowIndex] &&
          (('disclaimer' in uploadList[rowIndex]) && !isEmptyString(uploadList[rowIndex].disclaimer));

        const disclaimerClasses = cn(dataTableStyles.actionItem, {
          [dataTableStyles.isActionItemVisible]: hasDisclaimer,
        });

        const disclaimerTooltipContent = () => {
          const disclaimer = uploadList[rowIndex];

          if (typeof disclaimer !== 'undefined') {
            if (('disclaimer' in uploadList[rowIndex]) && !isEmptyString(disclaimer.disclaimer)) {
              return 'Edit file disclaimer';
            }

            return 'Add file disclaimer';
          }

          return undefined;
        };

        const supportsViewOnly = isViewOnlySupported(row.uuid);
        const isViewOnly = uploadList[rowIndex]?.viewOnly;

        const viewOnlyTooltipContent = isViewOnly ?
          'This file is set to "View Only" and is non-downloadable and non-printable.' :
          'This file is downloadable';

        const ViewOnlyIcon = isViewOnly ? IconLock : IconUnlock;

        const viewOnlyClasses: string = cn(dataTableStyles.actionItem, {
          [dataTableStyles.isActionItemVisible]: isViewOnly,
          [dataTableStyles.isActionItemHoveredVisible]: supportsViewOnly,
        });

        return (
          <>
            { supportsViewOnly && (
              <div className={ viewOnlyClasses }>
                <span
                  onClick={ () => handleViewOnlyChange(rowIndex) }
                  data-test="viewOnlyChange"
                >
                  <Tooltip
                    hideOnClick
                    placement="bottom"
                    content={ viewOnlyTooltipContent }
                  >
                    <ViewOnlyIcon />
                  </Tooltip>
                </span>
              </div>
            ) }
            { supportsDisclaimer && (
              <div className={ disclaimerClasses }>
                <Tooltip
                  placement="bottom"
                  content={ disclaimerTooltipContent() }
                >
                  <span
                    onClick={ () => showModal(row.uuid, uploadList[rowIndex].disclaimer) }
                    data-test="fileDisclaimer"
                  >
                    <IconDescription />
                  </span>
                </Tooltip>
              </div>
            ) }
            <div className={ dataTableStyles.actionItem }>
              <span
                onClick={ () => handleRemoveFile(row.uuid, rowIndex) }
                data-test="removeFile"
              >
                <Tooltip
                  placement="bottom"
                  content="Delete file"
                >
                  <IconTrash />
                </Tooltip>
              </span>
            </div>
          </>
        );
      },
    },
    {
      name: 'dragAndDrop',
      width: getColumnWidth('dragAndDrop'),
      isVisible: isColumnVisible('dragAndDrop'),
      className: dataTableStyles.draggableCol,
      isDraggable: true,
      title: '',
    },
  ];

  return (
    <div>
      { !!uploadFiles.sort.length && (
        <div
          data-test="uploadTable"
          className={ styles.uploadTableWrp }
        >
          <DataTable
            isDraggable
            dataTest="uploadDataTable"
            orderCallback={ handleUploadOrderChange }
            className={ dataTableStyles.isSecondaryTable }
            columns={ tableColumns }
            data={ uploadFiles.sort }
          />
        </div>
      ) }
      <div className={ cardStyles.cardInner } data-test="uploadDropzone">
        { uploadDropzoneArea }
      </div>
    </div>
  );
};

export default UploadTable;
