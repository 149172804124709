export enum TrancheGroup {
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  TIMING = 'TIMING',
  RATING_AGENCIES = 'RATING_AGENCIES',
  LEADS = 'LEADS',
  DEAL_MODIFIERS = 'DEAL_MODIFIERS',
  PRICE_PROGRESSION = 'PRICE_PROGRESSION',
  PRICING_DETAILS = 'PRICING_DETAILS',
  PERFORMANCE = 'PERFORMANCE',
  UNIQUE_IDENTIFIERS = 'UNIQUE_IDENTIFIERS',
  COMMENTS = 'COMMENTS',
}

export const TRANCHE_GROUPS_LABELS = {
  [TrancheGroup.ANNOUNCEMENT]: 'Announcement',
  [TrancheGroup.TIMING]: 'Timing',
  [TrancheGroup.RATING_AGENCIES]: 'Rating Agencies',
  [TrancheGroup.LEADS]: 'Leads',
  [TrancheGroup.DEAL_MODIFIERS]: 'Deal Modifiers',
  [TrancheGroup.PRICE_PROGRESSION]: 'Price Progression',
  [TrancheGroup.PRICING_DETAILS]: 'Pricing Details',
  [TrancheGroup.PERFORMANCE]: 'Performance',
  [TrancheGroup.UNIQUE_IDENTIFIERS]: 'Unique Identifiers',
  [TrancheGroup.COMMENTS]: 'Commentary',
};

export const INITIAL_COLLAPSED_GROUPS = [
  TrancheGroup.RATING_AGENCIES,
  TrancheGroup.LEADS,
  TrancheGroup.DEAL_MODIFIERS,
  TrancheGroup.PRICE_PROGRESSION,
  TrancheGroup.PRICING_DETAILS,
  TrancheGroup.PERFORMANCE,
  TrancheGroup.UNIQUE_IDENTIFIERS,
  TrancheGroup.COMMENTS,
];
