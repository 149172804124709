import { combineReducers } from 'redux';
import metadata from './metadata/reducer';
import loginsByUSContacts from './loginsByUSContacts/reducer';
import loginsByGlobalContacts from './loginsByGlobalContacts/reducer';
import totalViewTimeByDate from './totalViewTimeByDate/reducer';
import totalUniqueLoginsByDate from './totalUniqueLoginsByDate/reducer';
import totalViewTimeBySlide from './totalViewTimeBySlide/reducer';
import componentsStats from './componentsStats/reducer';
import downloadExcel from './downloadExcel/reducer';

export default combineReducers({
  metadata,
  loginsByUSContacts,
  loginsByGlobalContacts,
  totalViewTimeByDate,
  totalUniqueLoginsByDate,
  totalViewTimeBySlide,
  componentsStats,
  downloadExcel,
});
